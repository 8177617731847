import React, { useState, useEffect } from "react";
import * as JsSearch from "js-search";
import { useStaticQuery, graphql } from "gatsby";

export const SearchContext = React.createContext();
export const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState(null);
  const [newsResults, setNewsResults] = useState([]);
  const [achievementsResults, setAchievementsResults] = useState([]);
  const { allStrapiNew, allStrapiPage } = useStaticQuery(graphql`
    query {
      allStrapiNew {
        edges {
          node {
            id
            locale
            ExternalLink
            Description {
              data {
                Description
              }
            }
            Slug
            Title
            Category
            Media {
              Media {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      allStrapiPage {
        edges {
          node {
            Sections {
              ... on STRAPI__COMPONENT_SECTIONS_IMAGE_WITH_COPY_SLIDER {
                id
                Slides {
                  Description
                  Image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  Title
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const searchInstance = new JsSearch.Search("id");
    searchInstance.addIndex("Title");
    searchInstance.addIndex("Description");

    const flattenNews = allStrapiNew.edges.map((edge) => ({
      ...edge.node,
      Description: edge.node.Description.data.Description,
      type: "news",
    }));

    let flattenPages = [];
    if (allStrapiPage && allStrapiPage.edges) {
      flattenPages = allStrapiPage.edges.flatMap((edge) => {
        if (edge?.node?.Sections) {
          // Проверяем существование Sections
          return edge.node.Sections.flatMap((section) => {
            if (Array.isArray(section?.Slides)) {
              return section.Slides.map((slide) => ({
                ...slide,
                Description: slide?.Description, // Добавляем опциональную цепочку для Description
                type: "achievements",
              }));
            }
            return [];
          });
        }
        return [];
      });
    }

    searchInstance.addDocuments(flattenNews);
    searchInstance.addDocuments(flattenPages);

    setSearch(searchInstance);
  }, [allStrapiNew, allStrapiPage]);

  const onSearch = (e) => {
    const query = typeof e === "string" ? e : e.target.value;
    const results = search.search(query);

    setNewsResults(results.filter((result) => result.type === "news"));
    setAchievementsResults(
      results.filter((result) => result.type === "achievements")
    );
  };

  const onTagClick = (tag) => {
    onSearch(tag);
  };

  return (
    <SearchContext.Provider
      value={{ onSearch, onTagClick, search, newsResults, achievementsResults }}
    >
      {children}
    </SearchContext.Provider>
  );
};
